.homepage {
  text-align: center;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #123456; /* Replace with your brand color */
}

.logo {
  font-size: 2rem;
  color: white;
}

.contact-button,
.explore-button {
  /* Shared styles for buttons */
  display: inline-block;
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  background-color: #123456; /* Replace with your desired color */
  color: white;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
}

.contact-button:hover,
.explore-button:hover {
  /* Hover state styles */
  background-color: #0f2345; /* Slightly darker shade for hover state */
}

/* ...other styles... */

/* Add additional styles for hero, services, contact, and footer */

.hero {
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white; /* Ensure this color contrasts well with your background image */
  width: 100%;
  height: 100vh; /* Adjust height as needed */
}

.hero-content {
  text-align: center;
  max-width: 1200px; /* Adjust as needed for your design */
  margin: 0 auto;
  padding: 2rem;
  background: rgba(
    0,
    0,
    0,
    0.5
  ); /* Optional: dark overlay for better text visibility */
}

/* Update button styling as needed to ensure it stands out over the image */
.header {
  background-color: white; /* Set header background to white */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: adds a subtle shadow for depth */
}

.logo {
  height: 50px; /* Set a fixed height for your logo */
  /* Adjust the width automatically based on the height to maintain aspect ratio */
}

.services {
  padding: 4rem 0; /* More vertical padding for the services section */
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 40px; /* Increased gap size */
  margin: 0 auto;
  max-width: 1200px;
  padding: 1rem;
}

.service-item {
  background: #fff; /* White background for each service item */
  border: 1px solid #eaeaea;
  border-radius: 5px;
  padding: 2rem;
  text-align: center; /* Center-aligning the content */
  display: flex;
  flex-direction: column; /* Stack image and text vertically */
  align-items: center; /* Center items horizontally */
}

.service-image {
  width: 100%; /* Full width of the container */
  height: auto; /* Maintain aspect ratio */
  border-radius: 5px; /* Optional: if you want rounded corners on the images */
  margin-bottom: 1.5rem; /* Space between image and text */
}

/* ...other styles... */
